import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './components/app.component';
import { GlobalErrorHandlerService } from './services/common/global-error-handler.service';
import { CommonService } from './services/common/common.service';
import { EPlatformInterceptor } from './interceptor/httpclient.interceptor';
import { UserTimeoutService } from '../app/services/common/user-timeout.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

export function init_app(global: CommonService) {
  return () => global.getGlobalData();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    ServiceWorkerModule.register('service-worker.js',
      {
        enabled: true,
        registrationStrategy: 'registerImmediately'
      }
    ),
    NgxMatomoTrackerModule.forRoot({ trackerUrl: environment.matomoUrl, siteId: environment.matomoId })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: EPlatformInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [CommonService],
      multi: true
    },
    UserTimeoutService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
