import { Injectable } from '@angular/core';
import { IActionsTiles, IGlobalSettings, IProgramDefaults } from 'src/app/model/common';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ProgramDefaultService {
  private _defaults: IProgramDefaults[] = [];
  private _actionTiles: IActionsTiles[] = [];
  private _settings!: IGlobalSettings;
  constructor(private common: CommonService) {
    this.getProgramDefaultData();
  }

  /**
   * Get the Program Default data from the Program Service API.
   */
  getProgramDefaultData(): void {
    this.common.getGlobal().subscribe(data => {
      this._settings = data as any;
      this._defaults = this._settings.Programs?.filter((y:any)=> y.ProgramId == this.common.getProgramId())[0].ProgramDefaults;
      this._actionTiles = this._settings.Programs?.filter((y:any)=> y.ProgramId == this.common.getProgramId())[0].ProgramServiceTypes;
    });
  }

  get brandName(): string {
    return this._settings?.BrandingName;
  }

  get defaults(): IProgramDefaults[] {
    return this._defaults;
  }

  get PayerNames(): string[] {
    const rawData: string = this._defaults.filter(d => d.Field === 'PayerNames')[0].Value;
    const parsedData = JSON.parse(rawData);
    const payerNames = [];
    for (let data of parsedData) {
      payerNames.push(data.value);
    }
    return payerNames;
  }

  get ProgramId(): number {
    return this._defaults[0].ProgramId;
  }

  get ProgramServicesType(): IActionsTiles[] {
    return this._settings.Programs?.filter((y:any)=> y.ProgramId == this.common.getProgramId())[0].ProgramServiceTypes;
  }

  getProgramDefaultByName(fieldName: string): IProgramDefaults[] {
    const settings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings') || '');
    return settings.Programs?.filter((y:any)=> y.ProgramId == this.common.getProgramId())[0].ProgramDefaults.filter(p => p.Field === fieldName);
  }

}
