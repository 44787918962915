import { AfterViewChecked, AfterViewInit, Component, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { CommonService } from 'src/app/services/common/common.service';
import { HomeService } from 'src/app/services/home.service';
import { SwUpdate } from '@angular/service-worker';
import { ILocStrgUser } from 'src/app/model/user';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit, AfterViewChecked {
  title = 'Patient Portal';
  objectLoadedSubscription = new Subscription();

  constructor(
    private tracker: MatomoTracker,
    private commonService: CommonService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private homeService: HomeService,
    private swUpdate: SwUpdate
  )
  {
    this.commonService.patternStyleLoading$.subscribe((progress) =>
    {
      if(progress=="triggered")
      {
        this.commonService.setLibraryFiles(this.document);
      }
    })
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((evt:any) => {
        console.log('swswUpdate.isEnabled: '+ evt.type);
        debugger;
        
        // window.location.reload();
      });
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(function () {
        let postBack = false;
        navigator.serviceWorker.addEventListener('message', function (event) {
          if (event.data == 'InitiateValidateSession') {
            postBack = true;
          }
          if (event.data == 'InvalidSession') {
            sessionStorage.removeItem('user');
            sessionStorage.removeItem("SessionToken");
            postBack = false;

          }
        });
        //2 minute intervals
        setInterval(function () { SendSessionInfo(postBack) }, 120000);
      })
    }

    function SendSessionInfo(postBack: boolean) {
      if (navigator.serviceWorker.controller) {
        if (postBack) {
          let sessionToken = sessionStorage.getItem("SessionToken")! == null ? '' : sessionStorage.getItem("SessionToken")!;
          let user: ILocStrgUser = JSON.parse(sessionStorage.getItem('user')!);
          if (sessionToken != '' && user && environment.serviceUrl) {
            //send token, email and baseurl to service worker so it can call ValidateUserSession api
            navigator.serviceWorker.controller.postMessage({ 'SessionToken': sessionToken, 'Email': user.name, 'BaseUrl': environment.serviceUrl });
          }
          else {
            //sessiontoken or email doesn't exist
            navigator.serviceWorker.controller.postMessage('');
          }
          postBack = false;
        }
      }
      else {
        navigator.serviceWorker.register("service-worker.js")
          .then(function (registration) {
            //    window.location.reload();
          })
          .catch(function (error) {
          });
      }
    }

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.tracker.trackPageView('programName=' + this.commonService.getProgramName());
      }
    });
  }

  ngAfterViewInit() {
    if (window.location.href.indexOf('dashboard') > 0) {
      this.objectLoadedSubscription = this.homeService.userObjectLoaded$.subscribe((msg) => {
        if (msg) {
          setTimeout(() => {
            let loader = this.renderer.selectRootElement('.loader');
            this.renderer.setStyle(loader, 'display', 'none');
          }, 0);
        }
      });
    }
    else {
      setTimeout(() => {
        let loader = this.renderer.selectRootElement('.loader');
        this.renderer.setStyle(loader, 'display', 'none');
      }, 2000);

    }

  }


  ngAfterViewChecked(){

  }
}
